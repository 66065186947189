import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountFacade } from 'ish-core/facades/account.facade';
import { AppFacade } from 'ish-core/facades/app.facade';
import { User } from 'ish-core/models/user/user.model';
import { DeviceType } from 'ish-core/models/viewtype/viewtype.types';

type CollapsibleComponent = 'search' | 'navbar' | 'minibasket';

/**
 * The Header Component displays the page header.
 *
 * It uses the {@link LoginStatusComponent} for rendering the users login status.
 * It uses the {@link ProductCompareStatusComponent} for rendering the product compare button and count.
 * It uses the {@link MobileBasketComponent} for rendering the mobile basket button and basket item count.
 * It uses the {@link LanguageSwitchComponent} for rendering the language selection dropdown.
 * It uses the {@link SearchBoxComponent} for rendering the search box.
 * It uses the {@link HeaderNavigationComponent} for rendering the pages main navigation.
 * It uses the {@link MiniBasketComponent} for rendering mini basket on desktop sized viewports.
 *
 * @example
 * <ish-header></ish-header>
 */
@Component({
  selector: 'ish-header-default',
  templateUrl: './header-default.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDefaultComponent implements OnChanges, OnInit, OnDestroy {
  @Input() isSticky = false;
  @Input() deviceType: DeviceType;
  @Input() reset: unknown;
  openSearchValue: boolean;
  currentLocale: string;
  user$: Observable<User>;
  currentLocale$: Observable<string>;

  activeComponent: CollapsibleComponent = 'search';

  private destroy$ = new Subject<void>();

  constructor(private router: Router, private accountFacade: AccountFacade, private appFacade: AppFacade) {}

  ngOnInit() {
    this.user$ = this.accountFacade.user$;
    this.currentLocale$ = this.appFacade.currentLocale$;
    this.currentLocale$.pipe(takeUntil(this.destroy$)).subscribe(locale => {
      this.currentLocale = locale;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reset) {
      this.activeComponent = 'search';
    }
    this.toggleSpecialStatusOfSearch();
  }

  get showSearch() {
    return (
      this.activeComponent === 'search' &&
      // always show for sticky header
      (this.deviceType === 'mobile' || this.isSticky)
    );
  }

  get showNavBar() {
    return (
      this.activeComponent === 'navbar' ||
      // always show for desktop
      this.deviceType === 'desktop' ||
      // always show for tablet on top
      (this.deviceType === 'tablet' && !this.isSticky)
    );
  }

  get showDesktopLogoLink() {
    return (!this.isSticky && this.deviceType === 'tablet') || this.deviceType === 'desktop';
  }

  get showMobileLogoLink() {
    return (this.isSticky && this.deviceType !== 'desktop') || this.deviceType === 'mobile';
  }

  private toggleSpecialStatusOfSearch() {
    // deactivate search when switching to sticky header
    if (this.isSticky && this.activeComponent === 'search') {
      this.activeComponent = undefined;
    }
    // activate search when scrolling to top and no other is active
    if (!this.isSticky && !this.activeComponent) {
      this.activeComponent = 'search';
    }
  }

  toggle(component: CollapsibleComponent) {
    if (this.activeComponent === component) {
      // activate search bar when on top and no other is active
      this.activeComponent = !this.isSticky ? 'search' : undefined;
    } else {
      this.activeComponent = component;
    }
  }

  openSearchPage() {
    this.router.navigate(['/search']);
  }

  openSearch() {
    this.openSearchValue = !this.openSearchValue;
  }

  getLocaleBasedNiceHref(): string {
    if (this.currentLocale === 'pl_PL') {
      return 'https://www.nice.pl/#firstPage';
    } else if (this.currentLocale === 'it_IT') {
      return 'https://niceforyou.com';
    }

    return '';
  }

  getLocaleBasedB2BHref(): string {
    if (this.currentLocale === 'pl_PL') {
      return 'https://partnershop.niceforyou.com/pl_pl/';
    } else if (this.currentLocale === 'it_IT') {
      return 'https://partnershop.niceforyou.com/it_it/';
    }

    return '';
  }
}
